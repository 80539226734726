import ApiService from '../../services/ApiService';

const fetchRecruits = async (
  offset = 0,
  limit = 12,
  sorting = { field: 'name', order: 1 },
  filter = { field: '', value: '' }
) => {
  try {
    const { data } = await ApiService.get('/recruits', {
      params: {
        offset,
        limit,
        sortBy: sorting.field,
        sortOrder: sorting.order,
        filterBy: filter.field,
        filterValue: filter.value,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    return {
      data: [],
      total: 0,
      strengthTotal: 0,
    };
  }
};

const createRecruit = async (recruit) => {
  const { data } = await ApiService.post('/recruits', recruit);
  return data;
};

const editRecruit = async (id, update) => {
  const { data } = await ApiService.put(`/recruits/${id}`, update);
  return data;
};

const deleteRecruit = async (id) => {
  try {
    const { data } = await ApiService.delete(`/recruits/${id}`);
    const { success } = data;
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Service = {
  fetchRecruits,
  createRecruit,
  editRecruit,
  deleteRecruit,
};

export default Service;
