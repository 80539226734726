import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../context/AuthProvider';
import AppLoading from './AppLoading';
import Layout from './Layout';
import LoginPage from './Login';
import RecruitsPage from './Recruits';
import SettingsPage from './Settings';
import ChangePasswordPage from './Login/ChangePasswordPage';
import AskOtpPage from './Login/AskOtpPage';
import AdminsPage from './Admins';

export const routes = [
  {
    module: 'recruits',
    path: '/recruits',
    page: <RecruitsPage />,
  },
  {
    module: 'admin_management',
    path: '/admin_management',
    page: <AdminsPage />
  },
];

const settingsRoute = {
  module: 'settings',
  path: '/settings',
  page: <SettingsPage />,
};

export default function MainRouter() {
  const authContext = useAuthContext();
  const [components, setComponents] = useState([]);
  const [redirectData, setRedirectData] = useState({ redirects: false, to: '' });

  useEffect(() => {
    if (!authContext.me) return;
    const comps = authContext.me.allowedModules.reduce((acc, mod) => ([...acc, ...getComponentFromModule(mod)]), []);

    comps.push(settingsRoute);
    setComponents(comps);

    const [, path] = window.location.pathname.split('/');
    const isRouteValid = comps.find((c) => c.path === `/${path}`);

    if (!isRouteValid) {
      setRedirectData({ redirects: true, to: comps[0].path });
    }
  }, [authContext.me]);

  const getComponentFromModule = (mod) => {
    return routes.filter((route) => route.module === mod.module.key);
  }

  const getRoutesFromComponent = (comp, i) => (
    <Route key={`${comp.module}-${i}`} path={comp.path} exact>
      {comp.page}
    </Route>
  );

  const generateRoutes = () => {
    const moduleRoutes = components.map((comp, i) => getRoutesFromComponent(comp, i));
    return moduleRoutes;
  };

  if (!authContext.isAppLoaded) {
    return <AppLoading />;
  }

  if (!authContext.isAuthenticated) {
    return <LoginPage />;
  }

  if (authContext.me.auth.twoFAEnabled && !authContext.otpVerified) {
    return <AskOtpPage />;
  }

  if (!authContext.me.passwordUpdated) {
    return <ChangePasswordPage />;
  }

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {generateRoutes()}
          {redirectData.redirects && <Redirect to={redirectData.to} />}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
