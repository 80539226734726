import UAParser from 'ua-parser-js';

const parsedUA = new UAParser(navigator.parsedUA);
export const isMobile = /iOS|Android/i.test(parsedUA.getOS().name);

export const PRIVILEGES = {
  READ: 'read',
  READ_WRITE: 'read-write',
};

export const ROLES = {
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin',
};

export const DRAWER_WIDTH = 230;

export const ROW_PER_PAGE = 12;
