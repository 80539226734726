import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

export default function PasswordInput({
  variant,
  label,
  size,
  required,
  itemRef,
  value = '',
  onChange = () => {},
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant={variant} size={size} style={{ width: '100%' }}>
      <InputLabel htmlFor='outlined-adornment-password' required={required}>{label}</InputLabel>
      <OutlinedInput
        inputRef={itemRef}
        type={showPassword ? 'text' : 'password'}
        // value={value}
        // onChange={onChange}
        required={required}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='Toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e) => e.preventDefault()}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={`${label} a`}  // extra character to make space for asterisk :3
      />
    </FormControl>
  );
}
