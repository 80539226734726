import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';

import {
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMoreIcon, ExpandLessIcon } from '../../components/common/Icons';
import { isMobile, DRAWER_WIDTH } from '../../utils/constants';
import { LINKS, SA_SETTINGS_LINK, SETTINGS_LINK } from './links';
import { useAuthContext } from '../../context/AuthProvider';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  drawerTitle: {
    fontWeight: 600,
  },
  listItemSelected: {
    backgroundColor: `${fade(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    borderRight: `3px solid ${theme.palette.primary.main} !important`,
  },
  listItemIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItemIconSelected: {
    color: `${theme.palette.primary.main} !important`,
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
}));

const NestedLink = ({ item, selected, onClick }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isSelectedInNested = item.links.find((link) => link.key === selected);
    if (isSelectedInNested) {
      setOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ListItem
        button
        key={item.key}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon className={clsx(classes.listItemIcon)}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} primaryTypographyProps={{ variant: 'subtitle2' }} />
        {open ? <ExpandLessIcon size={20} /> : <ExpandMoreIcon size={20} />}
      </ListItem>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {item.links.map((link) => (
            <ListItem
              button
              key={link.key}
              className={clsx(classes.nested, {
                [classes.listItemSelected]: selected === link.key,
              })}
              selected={selected === link.key}
              onClick={() => onClick(link)}
            >
              <ListItemIcon className={clsx(classes.listItemIcon, { [classes.listItemIconSelected]: selected === link.key })}>{link.icon}</ListItemIcon>
              <ListItemText primary={link.text} primaryTypographyProps={{ variant: 'subtitle2' }} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default function SideBar({ open, setOpen }) {
  const classes = useStyles();

  const authContext = useAuthContext();
  const history = useHistory();

  const [selected, setSelected] = useState('dashboard');
  const [navLinkGroups, setNavLinkGroups] = useState([]);

  useEffect(() => {
    const links = authContext.me.allowedModules
      .reduce((acc, mod) => {
        const filtered = LINKS.filter((l) => l.module === mod.module.key);
        return [...acc, ...filtered];
      }, [])
      .sort((a, b) => a.key - b.key);

    const settings = authContext.me.role === 'superadmin' ? SA_SETTINGS_LINK : SETTINGS_LINK;
    setNavLinkGroups([...links, settings]);

    const path = history.location.pathname.replace('/', '');
    if (path) {
      setSelected(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onLinkClick = (item) => {
    setSelected(item.to.replace('/', ''));
    if (isMobile) {
      setOpen(false);
    }
    history.push(item.to);
  }

  return (
    <Drawer
      className={classes.drawer}
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor='left'
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography variant='subtitle1' noWrap className={classes.drawerTitle}>
          CC Admin Dashboard
            </Typography>
      </div>
      <List>
        {navLinkGroups.map((item) => {
          if (item.type === 'link') {
            return <ListItem
              button
              key={item.key}
              className={clsx({
                [classes.listItemSelected]: selected === item.to.replace('/', ''),
              })}
              selected={selected === item.key}
              onClick={() => _onLinkClick(item)}
            >
              <ListItemIcon className={clsx(classes.listItemIcon, { [classes.listItemIconSelected]: selected === item.to.replace('/', '') })}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} primaryTypographyProps={{ variant: 'subtitle2' }} />
            </ListItem>
          }

          return <NestedLink key={item.key} item={item} selected={selected} onClick={_onLinkClick} />;
        })}
      </List>
    </Drawer>
  );
}
