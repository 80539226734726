import AuthProvider from './context/AuthProvider';
import DataProvider from './context/DataProvider';
import MainRouter from './pages/MainRouter';
import {
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1d6ed8',
    }
  },
});

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainRouter />
        </ThemeProvider>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
