import ApiService from '../../services/ApiService';

const updatePassword = async (oldPassword, newPassword) => {
  const { data } = await ApiService.post('/admin/update_password', { oldPassword, newPassword });
  return data;
};

const getMFAUri = async () => {
  const { data } = await ApiService.get('/admin/get_mfa_uri');
  return data;
};

const verifyOtp = async (otp, isSetup = false) => {
  const { data } = await ApiService.post('/admin/verify_otp', { otp, isSetup });
  return data;
};

const disableMfa = async () => {
  try {
    await ApiService.post('/admin/disable_mfa');
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Service = {
  updatePassword,
  getMFAUri,
  verifyOtp,
  disableMfa,
};

export default Service;
