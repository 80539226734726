import {
  InputLabel,
  Button,
  IconButton,
} from '@material-ui/core';
import { Delete, } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';

export default function Upload({ id, label, value, onChange }) {
  const inputRef = useRef();
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (value) {
      setFile(value);
    }
  }, [value]);

  const openFileBrowser = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const f = e.target.files[0];
    if (f) {
      const url = URL.createObjectURL(f);
      setFile(url);

      onChange(f);
    }
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      {file && (
        <div style={{ display: 'flex', marginTop: 15 }}>
          <img src={file} alt='Uploaded' width='50%' style={{ border: '1px solid rgba(0,0,0, 0.3)', borderRadius: 4 }} />
          <div style={{ margin: '0 5px' }} />
          <IconButton style={{ height: 'fit-content', color: 'red' }} onClick={() => setFile(null)}>
            <Delete />
          </IconButton>
        </div>
      )}
      <input type='file' id={id} accept='image/x-png,image/jpeg' ref={inputRef} onChange={handleChange} style={{ display: 'none' }} />

      <label htmlFor={id}>
        <Button variant='contained' color='primary' disableElevation onClick={openFileBrowser} style={{ marginTop: 15 }}>
          ပုံရွေးရန်
        </Button>
      </label>
    </>
  );
}
