import {
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
  }
}));

export default function AppLoading() {
  const classes = useStyles();

  return (
    <Grid container direction='column' spacing={3} className={classes.root} alignItems='center' justify='center'>
      <Grid item>
        <CircularProgress color='inherit' />
      </Grid>
      <Grid item>
        <Typography variant='subtitle2'>App is loading...</Typography>
      </Grid>
    </Grid>
  );
}
