import { useRef, useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Notification from '../../components/common/Notification';
import { isMobile } from '../../utils/constants';
import Service from './service';
import PasswordInput from '../../components/common/PasswordInput';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(2, 3),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  textfield: {
    width: '100%',
  }
}));

const checkPassword = (str) => {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
};

export default function ChangePasswordPanel({ contentDirection = 'row', onSuccess = () => {} }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    visible: false,
    severity: 'success',
    content: '',
  });

  const oldPwRef = useRef();
  const newPwRef = useRef();
  const confirmNewPwRef = useRef();

  const changePassword = async () => {
    setLoading(true);

    const oldPassword = oldPwRef.current.value;
    const newPassword = newPwRef.current.value;
    const confirmNewPassword = confirmNewPwRef.current.value;

    if (newPassword !== confirmNewPassword) {
      setMessage({
        visible: true,
        severity: 'error',
        content: 'New passwords do not match.',
      });
      setLoading(false);
      return;
    }

    const isPwMatchCriteria = checkPassword(newPassword);
    if (!isPwMatchCriteria) {
      setMessage({
        visible: true,
        severity: 'error',
        content: 'Password must have at least 8 characters, including 1 uppercase character, 1 number and 1 special character.',
      });
      setLoading(false);
      return;
    }

    try {
      await Service.updatePassword(oldPassword, newPassword);

      oldPwRef.current.value = '';
      newPwRef.current.value = '';
      confirmNewPwRef.current.value = '';

      setMessage({
        visible: true,
        severity: 'success',
        content: 'Password updated successfully.',
      });
      onSuccess();
      setLoading(false);
    } catch (err) {
      console.log(err);
      console.log(err.response);
      setMessage({
        visible: true,
        severity: 'error',
        content: err.response ? err.response.data?.message : 'There is an error in connecting to server.',
      });
      setLoading(false);
    }
  };

  const isFull = contentDirection === 'column' || isMobile;

  return (
    <>
      <Paper className={classes.root}>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <Typography><b>Change New Password</b></Typography>
            <Typography variant='body2'>Password must have at least 8 characters, including 1 uppercase character, 1 number and 1 special character.</Typography>
          </Grid>
          <Grid item>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={isFull ? 12 : 4}>
                <PasswordInput itemRef={oldPwRef} className={classes.textfield} label='Old Password' size='small' variant='outlined' required />
              </Grid>
              <Grid item xs={isFull ? 12 : 4}>
                <PasswordInput itemRef={newPwRef} className={classes.textfield} label='New Password' size='small' variant='outlined' required />
              </Grid>
              <Grid item xs={isFull ? 12 : 4}>
                <PasswordInput itemRef={confirmNewPwRef} className={classes.textfield} label='Confirm Password' size='small' variant='outlined' required />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={changePassword} disabled={loading} disableElevation fullWidth={isFull}>
              {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
            Change Password
          </Button>
          </Grid>
        </Grid>
      </Paper>

      <Notification
        severity={message.severity}
        open={message.visible}
        handleClose={() => setMessage({ ...message, visible: false })}
        message={message.content}
      />
    </>
  )
}