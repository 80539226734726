import { useState, useRef, useEffect } from 'react';
import {
  Drawer,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import Upload from '../../components/Upload';
import NRCInput from '../../components/NrcInput';
import Service from './service';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    margin: theme.spacing(3),
  },
  textfield: {
    width: '100%',
  },
}));

const BLOOD_TYPES = ['O', 'O−', 'O+', 'A', 'A−', 'A+', 'B', 'B−', 'B+', 'AB', 'AB−', 'AB+']

export default function RecruitDetailDrawer({
  visible,
  drawerMode = 'create',
  onSuccess,
  onError,
  onClose,
  selectedItem,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const photoRef = useRef();
  const nameRef = useRef();
  const codeNameRef = useRef();
  const fatherNameRef = useRef();
  const educationRef = useRef();
  const occupationRef = useRef();
  const ethnicityReligionRef = useRef();
  const addressRef = useRef();
  const phoneRef = useRef();
  const secondaryPhoneRef = useRef();
  const signatureRef = useRef();

  const [dob, setDob] = useState(selectedItem.dob);
  const [nrc, setNrc] = useState(selectedItem.nrc);
  const [bloodType, setBloodType] = useState(selectedItem.bloodType);

  useEffect(() => {
    setDob(selectedItem.dob);
    setNrc(selectedItem.nrc);
    setBloodType(selectedItem.bloodType);
  }, [selectedItem]);

  const getFormData = () => {
    const formData = new FormData();
    formData.append('name', nameRef.current.value);
    formData.append('codeName', codeNameRef.current.value);
    
    Object.keys(nrc).forEach((k) => {
      formData.append(`nrc.${k}`, nrc[k]);
    });

    formData.append('fatherName', fatherNameRef.current.value);
    formData.append('dob', dob || '');
    formData.append('bloodType', bloodType);
    formData.append('education', educationRef.current.value);
    formData.append('occupation', occupationRef.current.value);
    formData.append('ethnicityReligion', ethnicityReligionRef.current.value);
    formData.append('address', addressRef.current.value);
    formData.append('phone', phoneRef.current.value);
    formData.append('secondaryPhone', secondaryPhoneRef.current.value);

    if (photoRef.current) {
      formData.append('photo', photoRef.current, 'photo.png');
    }
    if (signatureRef.current) {
      formData.append('signature', signatureRef.current, 'signature.png');
    }

    return formData;
  };

  const create = async () => {
    setLoading(true);
    try {
      const result = await Service.createRecruit(getFormData());
      onSuccess('Recruit added successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in adding the recruit.');
    }

    setLoading(false);
  };

  const edit = async () => {
    setLoading(true);

    try {
      const result = await Service.editRecruit(selectedItem._id, getFormData());
      onSuccess('Recruit updated successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in updating the recruit.');
    }

    setLoading(false);
  };

  return (
    <Drawer anchor='right' open={visible} onClose={onClose}>
      <Grid container direction='column' spacing={3} className={classes.root}>
        <Grid item>
          <Typography variant='h6'>{drawerMode === 'create' ? 'Create Recruit' : 'Edit Recruit'}</Typography>
        </Grid>
        <Grid item>
          <Upload label='ဓါတ်ပုံ' value={selectedItem.photoUrl} onChange={(data) => photoRef.current = data} />
        </Grid>
        <Grid item>
          <TextField inputRef={nameRef} defaultValue={selectedItem.name} className={classes.textfield} label='အမည်' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <TextField inputRef={codeNameRef} defaultValue={selectedItem.codeName} className={classes.textfield} label='ရဲဘော်အမည်' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          {/* <TextField inputRef={nrcRef} defaultValue={selectedItem.nrc} className={classes.textfield} label='မှတ်ပုံတင်အမှတ်' size='small' variant='outlined' /> */}
          <>
            <InputLabel style={{ marginBottom: 10 }}>မှတ်ပုံတင်အမှတ်</InputLabel>
            <NRCInput className={classes.textfield} value={nrc} onChange={setNrc} />
          </>
        </Grid>
        <Grid item>
          <TextField inputRef={fatherNameRef} defaultValue={selectedItem.fatherName} className={classes.textfield} label='အဘအမည်' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              size='small'
              className={classes.textfield}
              label='မွေးသက္ကရာဇ်'
              format='DD MMM yyyy'
              inputVariant='outlined'
              value={dob}
              onChange={(date) => {
                setDob(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <FormControl variant='outlined' size='small' className={classes.textfield}>
            <InputLabel>သွေးအုပ်စု</InputLabel>
            <Select
              label='သွေးအုပ်စု'
              value={bloodType}
              onChange={(e) => setBloodType(e.target.value)}
            >
              {BLOOD_TYPES.map((bt) => (
                <MenuItem key={bt} value={bt}>{bt}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField inputRef={educationRef} defaultValue={selectedItem.education} className={classes.textfield} label='ပညာအရည်အချင်း' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <TextField inputRef={occupationRef} defaultValue={selectedItem.occupation} className={classes.textfield} label='အလုပ်အကိုင်' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <TextField inputRef={ethnicityReligionRef} defaultValue={selectedItem.ethnicityReligion} className={classes.textfield} label='လူမျိုး/ဘာသာ' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <TextField
            inputRef={addressRef}
            defaultValue={selectedItem.address}
            className={classes.textfield}
            label='နေရပ်လိပ်စာ'
            size='small'
            variant='outlined'
            multiline
            rows={3}
          />
        </Grid>
        <Grid item>
          <TextField inputRef={phoneRef} defaultValue={selectedItem.phone} className={classes.textfield} label='ဖုန်းနံပါတ်' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <TextField inputRef={secondaryPhoneRef} defaultValue={selectedItem.secondaryPhone} className={classes.textfield} label='တဆင့်ဆက်သွယ်ရန်ဖုန်းနံပါတ်' size='small' variant='outlined' />
        </Grid>
        <Grid item>
          <Upload label='ထိုးမြဲလက်မှတ်' value={selectedItem.signatureUrl} onChange={(data) => signatureRef.current = data} />
        </Grid>

        <Grid item>
          <Button variant='contained' color='primary' onClick={drawerMode === 'create' ? create : edit} disabled={loading} disableElevation fullWidth>
            {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
            {drawerMode === 'create' ? 'Create Recruit' : 'Edit Recruit'}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
}
