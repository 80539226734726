import {
  Container,
  Grid,
  Button,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthContext } from '../../context/AuthProvider';
import ChangePasswordPanel from "../Settings/ChangePasswordPanel";

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function ChangePasswordPage() {
  const classes = useStyles();
  const authContext = useAuthContext();

  const updateMe = () => {
    setTimeout(() => {
      authContext.setMe({ ...authContext.me, passwordUpdated: true });
    }, 1000);
  };

  return (
    <Container maxWidth='xs' className={classes.root}>
      <Grid
        container
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item>
          <ChangePasswordPanel contentDirection='column' onSuccess={updateMe} />
        </Grid>
        <Grid item>
          <Button
            startIcon={<ExitToApp />}
            onClick={() => {
              localStorage.removeItem('_cred');
              authContext.setOtpVerified(false);
              authContext.setIsAuthenticated(false);
            }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
