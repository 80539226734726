import { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  Drawer,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,

  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,

  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Service from './service';
import PasswordInput from '../../components/common/PasswordInput';
import { isMobile } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: isMobile ? '100%' : 430,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root: {
    // width: isMobile ? `calc(100% - ${theme.spacing(3)})` : 400,
    // width: isMobile ? '100%' : 400,
    // width: '100%',
    flexWrap: 'nowrap',
    padding: theme.spacing(3),

    '&> div': {
      margin: '10px 0',
    },
  },
  textfield: {
    width: '100%',
  },
}));

const PermissionManager = ({ modules, value: state, onChange }) => {
  const handleAllow = (mod, checked, moduleIndex) => {
    if (checked) {
      const data = [
        ...state,
        { moduleId: mod._id, module: mod, privilege: 'read', order: moduleIndex },
      ];
      const sorted = data.sort((a, b) => a.order - b.order);
      onChange(sorted);
    } else {
      const newState = state.filter((st) => st.moduleId !== mod._id);
      onChange(newState);
    }
  };

  const handlePrivilege = (mod, val) => {
    const newState = state.map((st) => {
      if (st.moduleId === mod.moduleId) {
        return { ...st, privilege: val };
      }
      return st;
    });
    onChange(newState);
  };

  return (
    <TableContainer>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            <TableCell>Module</TableCell>
            <TableCell>Allowed</TableCell>
            <TableCell>Permission</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map((mod, i) => {
            const allowedMod = state.find((doc) => doc.moduleId === mod._id);

            return (
              <TableRow key={mod._id}>
                <TableCell>{mod.name}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={!!allowedMod}
                    color='primary'
                    inputProps={{ 'aria-label': 'checkbox' }}
                    onChange={(e, checked) => handleAllow(mod, checked, i)}
                  />
                </TableCell>
                <TableCell>
                  <RadioGroup
                    aria-label='privilege'
                    name='privilege'
                    value={allowedMod ? allowedMod.privilege : 'read'}
                    onChange={(e, val) => handlePrivilege(allowedMod, val)}
                  >
                    <FormControlLabel disabled={!allowedMod} value='read' control={<Radio size='small' color='primary' />} label='Read' />
                    <FormControlLabel disabled={!allowedMod} value='read-write' control={<Radio size='small' color='primary' />} label='Read/Write' />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function AdminDetailDrawer({
  visible,
  drawerMode = 'create',
  onSuccess,
  onError,
  onClose,
  selectedItem,
  modules: moduleData = [],
}) {
  const classes = useStyles();

  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);

  const [allowedModules, setAllowedModules] = useState(selectedItem.allowedModules);
  const adminIdRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    if (!moduleData.length) return;
    setModules(moduleData);
  }, [moduleData]);

  useEffect(() => {
    if (!modules.length || !selectedItem.allowedModules) return;

    const am = selectedItem.allowedModules.map((mod) => ({ ...mod, order: modules.findIndex((m) => m._id === mod.moduleId) }));
    setAllowedModules(am);
  }, [modules, selectedItem.allowedModules]);

  const getFormData = () => {
    const data = {
      adminId: adminIdRef.current.value,
      allowedModules,
    };

    if (drawerMode === 'create') {
      return { ...data, password: passwordRef.current.value, };
    }
    return data;
  };

  const create = async () => {
    setLoading(true);
    try {
      const result = await Service.createAdmin(getFormData());
      onSuccess('Admin added successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in adding the admin.');
    }

    setLoading(false);
  };

  const edit = async () => {
    setLoading(true);

    try {
      const result = await Service.editAdmin(selectedItem._id, getFormData());
      onSuccess('Admin updated successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in updating the admin.');
    }

    setLoading(false);
  };

  return (
    <Drawer anchor='right' open={visible} onClose={onClose} classes={{ paper: classes.drawer }}>
      <Grid container direction='column' spacing={0} className={classes.root}>
        <Grid item className={classes.drawerHeader}>
          <Typography variant='h6'>{drawerMode === 'create' ? 'Create Admin' : 'Edit Admin'}</Typography>
          <IconButton aria-label='close' onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item>
          <TextField inputRef={adminIdRef} defaultValue={selectedItem.adminId} className={classes.textfield} label='Admin ID' size='small' variant='outlined' />
        </Grid>
        {drawerMode === 'create' && (
          <Grid item>
            <PasswordInput itemRef={passwordRef} className={classes.textfield} label='Password' size='small' variant='outlined' required />
          </Grid>
        )}

        <Grid item>
          <PermissionManager
            modules={modules}
            value={allowedModules}
            onChange={setAllowedModules}
          />
        </Grid>

        <Grid item>
          <Button variant='contained' color='primary' onClick={drawerMode === 'create' ? create : edit} disabled={loading} disableElevation fullWidth>
            {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
            {drawerMode === 'create' ? 'Create Admin' : 'Edit Admin'}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
}
