import { useEffect, useRef, useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import Notification from '../../components/common/Notification';
import Badge from '../../components/common/Badge';
import { LockIcon } from '../../components/common/Icons';
import { isMobile } from '../../utils/constants';
import { useAuthContext } from '../../context/AuthProvider';
import Service from './service';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(3, 3),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  textfield: {
    width: '100%',
  }
}));

const checkOTPFormat = (content) => /\d{6}/gm.test(content);

export default function TwoFASetup() {
  const classes = useStyles();

  const authContext = useAuthContext();

  const otpRef = useRef();
  const [mfaUri, setMfaUri] = useState('');
  const [status, setStatus] = useState('disabled');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    visible: false,
    severity: 'success',
    content: '',
  });

  useEffect(() => {
    const sts = authContext.me.auth.twoFAEnabled ? 'enabled' : 'disabled';
    setStatus(sts);
  }, [authContext.me]);

  const getMFAUri = async () => {
    setLoading(true);
    const { uri = '' } = await Service.getMFAUri();
    setMfaUri(uri);
    setLoading(false);
    setStatus('ongoing');
  };

  const verifyOtp = async () => {
    setLoading(true);

    const otp = otpRef.current.value;
    if (!checkOTPFormat(otp)) {
      setMessage({
        visible: true,
        severity: 'error',
        content: 'Invalid OTP format.',
      });
      setLoading(false);
      return;
    }

    try {
      const { success } = await Service.verifyOtp(otp, true);
      if (success) {
        setMessage({
          visible: true,
          severity: 'success',
          content: 'Your account has now enabled two-factor authentication.',
        });
        authContext.setOtpVerified(true);
        authContext.setMe({ ...authContext.me, auth: { twoFAEnabled: true } });
        setStatus('enabled');
      } else {
        setMessage({
          visible: true,
          severity: 'error',
          content: 'OTP does not match.',
        });
      }
      setLoading(false);
    } catch (err) {
      setMessage({
        visible: true,
        severity: 'error',
        content: 'There is an error in matching OTP.',
      });
      setLoading(false);
    }
  };

  const disableTFA = async () => {
    setLoading(true);
    await Service.disableMfa();
    setLoading(false);
    authContext.setMe({ ...authContext.me, auth: { twoFAEnabled: false } });
  };

  const DisabledBlock = () => (
    <Grid container direction='row' spacing={isMobile ? 2 : 0}>
      <Grid item xs={12} sm={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <LockIcon size={45} />
      </Grid>
      <Grid item xs={12} sm={11}>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <Typography><b>Two-factor authentication</b></Typography>
          </Grid>
          <Grid item>
            <Typography>
              Two-factor authentication adds another layer of security to your account.
              You'll have to enter an OTP generated by time-based OTP apps.
                </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={loading}
              fullWidth={isMobile}
              disableElevation
              onClick={getMFAUri}
            >
              {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
              Enable 2FA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const OngoingBlock = () => (
    <Grid container direction='column' spacing={3}>
      <Grid item xs={12} sm={10}>
        <Typography>
          Scan the QR code below with an authenticator app. After setting up profile in the app, enter the OTP provided by the authenticator.
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={isMobile ? 2 : 0}>
          <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
            <QRCode value={mfaUri} size={200} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <TextField inputRef={otpRef} className={classes.textfield} type='number' label='OTP' size='small' variant='outlined' required />
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={loading}
                  fullWidth={isMobile}
                  disableElevation
                  onClick={verifyOtp}
                >
                  {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
              Verify
            </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const EnabledBlock = () => (
    <Grid container direction='row' spacing={isMobile ? 2 : 0}>
      <Grid item xs={12} sm={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <LockIcon size={45} />
      </Grid>
      <Grid item xs={12} sm={11}>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <div style={{ display: 'flex' }}>
              <Typography><b>Two-factor authentication</b></Typography>
              <div style={{ margin: '0 3px' }} />
              <Badge color='green'>Enabled</Badge>
            </div>
          </Grid>
          <Grid item>
            <Typography>
              Two-factor authentication adds another layer of security to your account.
              You'll have to enter an OTP generated by time-based OTP apps.
                </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              color='secondary'
              disabled={loading}
              fullWidth={isMobile}
              disableElevation
              onClick={disableTFA}
            >
              {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
              Disable 2FA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Paper className={classes.root}>
        {status === 'disabled' ? (
          <DisabledBlock />
        ) : status === 'ongoing' ? (
          <OngoingBlock />
        ) : <EnabledBlock />}
      </Paper>

      <Notification
        severity={message.severity}
        open={message.visible}
        handleClose={() => setMessage({ ...message, visible: false })}
        message={message.content}
      />
    </>
  )
}