import { createContext, useContext } from 'react';

const DataContext = createContext({
  townships: [],
});

export const useDataContext = () => useContext(DataContext);

const DataProvider = ({ children }) => {
  return (
    <DataContext.Provider value={{}}>
      {children}
    </DataContext.Provider>
  )
};

export default DataProvider;
