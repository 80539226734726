import { useState } from 'react';
import {
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import ProfileInformation from './ProfileInformation';
import SecuritySettings from './SecuritySettings';

function TabPanel({
  value,
  index,
  children,
  ...props
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && children}
    </div>
  );
}

export default function SettingsPage() {
  const [selected, setSelected] = useState(0);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h6'>Settings page</Typography>
      </Grid>
      <Grid item>
        <Tabs
          value={selected}
          indicatorColor='primary'
          textColor='primary'
          onChange={(e, val) => setSelected(val)}
          aria-label='Tabs'
        >
          <Tab label='Profile' />
          <Tab label='Security' />
        </Tabs>
      </Grid>
      <div style={{ marginTop: 10 }} />
      <Grid item>
        <TabPanel value={selected} index={0}>
          <ProfileInformation />
        </TabPanel>
        <TabPanel value={selected} index={1}>
          <SecuritySettings />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
