import clsx from 'clsx';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Menu as MenuIcon,
  ExitToApp,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useAuthContext } from '../../context/AuthProvider';
import { isMobile, DRAWER_WIDTH } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function AppBar({ open, setOpen }) {
  const classes = useStyles();
  const authContext = useAuthContext();
  const history = useHistory();

  return (
    <MuiAppBar
      elevation={0}
      color='default'
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar className={classes.appBarContent}>
        {isMobile ? (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => setOpen(!open)}
            edge='start'
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        ) : <div />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && (
            <>
              <Typography variant='body2'>Logged in as : <b>{authContext.me.adminId}</b></Typography>
              <div style={{ margin: '0 5px' }} />
            </>
          )}
          <Button
            startIcon={<ExitToApp />}
            onClick={() => {
              localStorage.removeItem('_cred');
              authContext.setOtpVerified(false);
              authContext.setIsAuthenticated(false);
              history.push('/');
            }}
          >
            Logout
          </Button>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}
