import {
  Grid,
} from '@material-ui/core';
import ChangePasswordPanel from './ChangePasswordPanel';
import TwoFASetup from './TwoFASetup';

export default function SecuritySettings() {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <ChangePasswordPanel />
      </Grid>
      <Grid item>
        <TwoFASetup />
      </Grid>
    </Grid>
  );
}
