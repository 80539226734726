import { useState } from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import { isMobile } from '../../utils/constants';
import AppBar from './AppBar';
import SideBar from './Sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    backgroundColor: 'white',
    height: `calc(100vh - 64px)`,
    marginTop: 64,
    flexGrow: 1,
    padding: isMobile ? theme.spacing(1, 3) : theme.spacing(3, 5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = useState(!isMobile);
  
  return (
    <div className={classes.root}>
      <AppBar open={open} setOpen={setOpen} />
      <SideBar open={open} setOpen={setOpen} />

      <main className={classes.content} >
        {children}
      </main>
    </div>
  );
}
