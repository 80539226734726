import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditIcon, DeleteIcon } from '../../components/common/Icons';
import MUIDataTable from 'mui-datatables';
import Service from './service';
import { useAuthContext } from '../../context/AuthProvider';
import { useDataContext } from '../../context/DataProvider';
import { ROW_PER_PAGE, PRIVILEGES } from '../../utils/constants';
import Notification from '../../components/common/Notification';
import AdminDetailDrawer from './AdminDetailDrawer';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  filterSelect: {
    minWidth: 250,
  },
  innerTableTitle: {
    width: '100%',
    // margin: theme.spacing(2),
  }
}));

const MODULE_KEY = 'admin_management';

const loadingComponent = (
  <div style={{ position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)' }}>
    <CircularProgress size={26} />
  </div>
);

export default function AdminsPage() {
  const classes = useStyles();

  const authContext = useAuthContext();
  const dataProvider = useDataContext();
  const [privilege, setPrivilege] = useState(PRIVILEGES.READ);

  const [modules, setModules] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});

  const [columns, setColumns] = useState([
    { name: 'adminId', label: 'Admin ID', options: { filter: false } },
    {
      name: 'allowedModules',
      label: 'Allowed Modules',
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value) => value.map((v) => v.module?.name).join(', '),
        customBodyRender: (value) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {value.map((v, i) => (
              <span key={i}>{v.module?.name} - {v.privilege === 'read' ? 'r' : 'r/w'}</span>
            ))}
          </div>
        )
      },
    },
    {
      name: 'passwordUpdated',
      label: 'Password Updated',
      options: {
        filter: false,
        customBodyRender: (value) => value ? 'Updated' : 'Not Updated',
      },
    },
    {
      name: 'auth.twoFAEnabled',
      label: '2FA Enabled',
      options: {
        filter: false,
        customBodyRender: (value) => value ? 'Enabled' : 'Disabled',
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        display: 'excluded',
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ display: 'flex' }}>
            <IconButton
              color='primary'
              onClick={() => {
                const item = tableMeta.tableData[tableMeta.rowIndex];
                setSelectedItem(item);
                setDrawerMode('edit');
                setDrawerVisible(true);
              }}
              style={{ padding: 6 }}
            >
              <EditIcon size={20} />
            </IconButton>
            <IconButton
              onClick={() => {
                const item = tableMeta.tableData[tableMeta.rowIndex];
                setSelectedItem(item);
                setDialogVisible(true);
              }}
              style={{ padding: 6, color: '#d82929' }}
            >
              <DeleteIcon size={20} />
            </IconButton>
          </div>
        )
      }
    },
  ]);

  const [filterValue, setFilterValue] = useState({ field: '', value: '' });
  const [sortOrder, setSortOrder] = useState({ field: 'adminId', order: 1 });

  const [message, setMessage] = useState({ visible: false, content: '' });

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerMode, setDrawerMode] = useState('create');

  useEffect(() => {
    const priv = authContext.me.allowedModules.find((mod) => mod.module.key === MODULE_KEY);
    setPrivilege(priv.privilege);

    const fetchData = async () => {
      const { data: result, total: totalItems } = await Service.fetchAdmins(0, ROW_PER_PAGE);
      const mods = await Service.fetchModules();

      setData(result);
      setTotal(totalItems);
      setModules(mods);

      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (privilege === PRIVILEGES.READ_WRITE) {
      const newCols = [...columns];
      const idx = newCols.findIndex((col) => col.name === 'actions');
      newCols[idx].options.display = true;
      setColumns([...newCols]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege]);

  const PageHeader = () => (
    <Grid container justify='space-between' className={classes.container}>
      <Grid item>
        <Typography variant='h6'>Admin List</Typography>
      </Grid>
      <Grid item>
        {privilege === PRIVILEGES.READ_WRITE && (
          <Button variant='contained' color='primary' disableElevation onClick={() => {
            setSelectedItem({
              adminId: '', allowedModules: [], password: '',
            });
            setDrawerMode('create');
            setDrawerVisible(true);
          }}>
            + Add Admin
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const QuickStats = () => (
    <Grid container spacing={1} direction='column'>
      <Grid item>
        <Typography>Total Admins - <b>{total}</b></Typography>
      </Grid>
    </Grid>
  );

  const handleFilterChange = async (changedCol, filterList, type, i, displayedData) => {
    setLoading(true);

    const value = filterList[i] ? filterList[i][0] : null;
    const township = dataProvider.townships.find((ts) => ts.name.en === value);

    const newFilterValue = { field: 'township', value: township?._id };
    const { data: filtered, total: totalItems } = await Service.fetchAdmins(0, ROW_PER_PAGE, sortOrder, newFilterValue);

    setFilterValue(newFilterValue);
    setData(filtered);
    setTotal(totalItems);
    setLoading(false);
  };

  const handleColumnSortChange = async (col, direction) => {
    setLoading(true);

    const newSortOrder = { field: col, order: direction === 'asc' ? 1 : -1 };
    const { data: sortedItems, total: totalItems } = await Service.fetchAdmins(0, ROW_PER_PAGE, newSortOrder, filterValue);

    setSortOrder(newSortOrder);
    setData(sortedItems);
    setTotal(totalItems);
    setLoading(false);
  };

  const handlePageChange = async (newPage) => {
    setLoading(true);

    const { data: result } = await Service.fetchAdmins(newPage, ROW_PER_PAGE, sortOrder, filterValue);
    setData(result);

    setLoading(false);
  };

  const deleteAdmin = async () => {
    setBtnLoading(true);

    const result = await Service.deleteAdmin(selectedItem._id);
    if (result) {
      const filtered = data.filter((doc) => doc._id !== selectedItem._id);
      setData(filtered);
      setMessage({
        type: 'success',
        visible: true,
        content: 'Admin deleted successfully.',
      });
    } else {
      setMessage({
        type: 'error',
        visible: true,
        content: 'There is an error in deleting the admin.',
      });
    }

    setBtnLoading(false);
    setDialogVisible(false);
  };

  return (
    <div>
      <PageHeader />
      <QuickStats />
      <Grid container spacing={1} direction='column' className={classes.container}>
        <Grid item>
          <div style={{ position: 'relative' }}>
            {loading && loadingComponent}
            <MUIDataTable
              columns={columns}
              data={data}
              options={{
                serverSide: true,
                filter: false,
                search: false,
                download: false,
                print: false,
                elevation: 0,
                tableId: 'dataTable',
                selectableRows: 'none',
                rowsPerPage: ROW_PER_PAGE,
                rowsPerPageOptions: [],
                count: total,
                onChangePage: handlePageChange,
                onColumnSortChange: handleColumnSortChange,
                onFilterChange: handleFilterChange,
                enableNestedDataAccess: '.',

                // expandableRows: true,
                // expandableRowsHeader: false,
                // expandableRowsOnClick: true,
                // renderExpandableRow: (rowData, rowMeta) => {
                //   const item = { ...data[rowMeta.dataIndex] };
                //   return (
                //     <>
                //       <TableRow>
                //         <TableCell colSpan={isMobile ? 2 : 3}>
                //           <Typography variant='subtitle2' className={classes.innerTableTitle}>Allowed Modules</Typography>
                //         </TableCell>
                //       </TableRow>
                //       {item.allowedModules.map((mod) => (
                //         <TableRow>
                //           <TableCell colSpan={isMobile ? 1 : 2}>
                //             <Typography variant='body2'>{mod.module.name}</Typography>
                //           </TableCell>
                //           <TableCell>
                //             <Typography variant='body2'>{mod.privilege}</Typography>
                //           </TableCell>
                //         </TableRow>
                //       ))}
                //     </>
                //   );
                // },
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Dialog
        maxWidth='xs'
        aria-labelledby='confirmation-dialog-title'
        open={dialogVisible}
      >
        <DialogTitle id='confirmation-dialog-title'>Delete Admin</DialogTitle>
        <DialogContent>
          <Typography>Do you want to delete "{selectedItem.adminId}"?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDialogVisible(false)}>
            Cancel
        </Button>
          <Button onClick={deleteAdmin} style={!btnLoading ? { color: 'red' } : {}} disabled={btnLoading}>
            {btnLoading && <CircularProgress size={20} style={{ color: 'red', marginRight: '.5rem' }} />}
            Delete
        </Button>
        </DialogActions>
      </Dialog>

      <Notification
        severity={message.type}
        open={message.visible}
        handleClose={() => setMessage({ ...message, visible: false })}
        message={message.content}
      />

      <AdminDetailDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        modules={modules}
        selectedItem={selectedItem}
        drawerMode={drawerMode}
        onSuccess={(msg, updated) => {
          setMessage({
            type: 'success',
            content: msg,
            visible: true,
          });
          setDrawerVisible(false);

          if (drawerMode === 'create') {
            const updatedList = [...data, updated];
            setData(updatedList);
          } else {
            const updatedList = data.map((doc) => {
              if (doc._id === updated._id) {
                return updated;
              }
              return doc;
            });

            setData(updatedList);
          }
        }}
        onError={(msg) => {
          setMessage({
            type: 'error',
            content: msg,
            visible: true,
          });
          setDrawerVisible(false);
        }}
      />
    </div>
  );
}
