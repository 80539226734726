import { createContext, useContext, useEffect, useState } from 'react';
import ApiService from '../services/ApiService';

const AuthContext = createContext({
  me: {},
  setMe: () => {},

  isAuthenticated: false,
  setIsAuthenticated: () => {},

  otpVerified: false,
  setOtpVerified: () => {},

  isAppLoaded: false,
});

export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [me, setMe] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);  // To check otp authentication if user has enabled.
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  useEffect(() => {
    const fetchMe = async () => {
      const { data } = await ApiService.get('/admin/me');
      setMe(data);
      setIsAuthenticated(true);
      setOtpVerified(true);
      setIsAppLoaded(true);
    };

    let creds = localStorage.getItem('_cred');
    if (creds) {
      creds = JSON.parse(creds);
      if (Date.now() > creds.expiresIn) {
        setIsAppLoaded(true);
        return;
      };

      fetchMe();
    } else {
      setIsAppLoaded(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, me, setMe, otpVerified, setOtpVerified, isAppLoaded }}>
      {children}
    </AuthContext.Provider>
  )
};

export default AuthProvider;
