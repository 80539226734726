import ApiService from '../../services/ApiService';

const login = async (id, password) => {
  const { data } = await ApiService.post('/admin/login', { id, password });
  return data;
};

const Service = {
  login,
};

export default Service;