import ApiService from '../../services/ApiService';

const fetchAdmins = async (
  offset = 0,
  limit = 12,
  sorting = { field: 'name', order: 1 },
  filter = { field: '', value: '' }
) => {
  try {
    const { data } = await ApiService.get('/admin', {
      params: {
        offset,
        limit,
        sortBy: sorting.field,
        sortOrder: sorting.order,
        filterBy: filter.field,
        filterValue: filter.value,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    return {
      data: [],
      total: 0,
    };
  }
};

const fetchModules = async () => {
  const { data } = await ApiService.get('/modules');
  return data;
};

const createAdmin = async (admin) => {
  const { data } = await ApiService.post('/admin', admin);
  return data;
};

const editAdmin = async (id, update) => {
  const { data } = await ApiService.put(`/admin/${id}`, update);
  return data;
};

const deleteAdmin = async (id) => {
  try {
    const { data } = await ApiService.delete(`/admin/${id}`);
    const { success } = data;
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Service = {
  fetchAdmins,
  fetchModules,
  createAdmin,
  editAdmin,
  deleteAdmin,
};

export default Service;
