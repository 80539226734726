import { useRef, useState } from 'react';
// import { useHistory } from 'react-router';
import {
  Container,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Notification from '../../components/common/Notification';
import { useAuthContext } from '../../context/AuthProvider';
import Service from '../Settings/service';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textfield: {
    width: '100%',
  },
});

const checkOTPFormat = (content) => /\d{6}/gm.test(content);

export default function AskOtpPage() {
  const classes = useStyles();

  const authContext = useAuthContext();

  const [message, setMessage] = useState({
    visible: false,
    severity: 'success',
    content: '',
  });
  const [loading, setLoading] = useState(false);

  const otpRef = useRef('');

  const verifyOTP = async () => {
    setLoading(true);

    const otp = otpRef.current.value;
    if (!checkOTPFormat(otp)) {
      setMessage({
        visible: true,
        severity: 'error',
        content: 'Invalid OTP format.',
      });
      setLoading(false);
      return;
    }

    try {
      const { success } = await Service.verifyOtp(otp, true);
      if (success) {
        authContext.setOtpVerified(true);
      } else {
        setMessage({
          visible: true,
          severity: 'error',
          content: 'OTP does not match.',
        });
        setLoading(false);
      }
    } catch (err) {
      setMessage({
        visible: true,
        severity: 'error',
        content: 'There is an error in matching OTP.',
      });
      setLoading(false);
    }
  };

  return (
    <Container maxWidth='xs' className={classes.root}>
      <Grid
        container
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs={11} sm={8}>
          <TextField inputRef={otpRef} className={classes.textfield} type='number' label='6-digit code' size='small' variant='outlined' required />
        </Grid>
        <Grid item xs={11} sm={8}>
          <Button variant='contained' color='primary' onClick={verifyOTP} disabled={loading} disableElevation fullWidth>
            {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
            Verify code
          </Button>
        </Grid>
      </Grid>
      
      <Notification
        severity={message.severity}
        open={message.visible}
        handleClose={() => setMessage({ ...message, visible: false })}
        message={message.content}
      />
    </Container>
  );
}
