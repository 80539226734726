import {
  UserGroupIcon,
  SettingsIcon,
  AdjustmentsIcon,
  AddUserIcon,
} from '../../components/common/Icons';

export const LINKS = [
  { key: 0, type: 'link', text: 'Recruits', to: '/recruits', module: 'recruits', icon: <UserGroupIcon /> },
];

export const SETTINGS_LINK = { key: 'settings', type: 'link', text: 'Settings', to: '/settings', icon: <SettingsIcon /> };

export const SA_SETTINGS_LINK = {
  key: 'settings_head',
  type: 'linkHead',
  text: 'Settings',
  icon: <AdjustmentsIcon />,
  links: [
    { key: 'settings', text: 'Acc Settings', to: '/settings', icon: <SettingsIcon /> },
    { key: 'admin_management', text: 'Admins', to: '/admin_management', module: 'admin_management', icon: <AddUserIcon /> },
  ]
};
