import {
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';
import './style.css';

const mode = 'ndummy';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Why in the world do we want to zero-index the month???
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

export default function RecruitCardView({ selectedItem }) {
  return (
    <div className='formSkeleton'>
      <div className='formCover'></div>
      <div className='formBody'>
        <div className='formHeader'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ fontSize: '1.7rem', fontWeight: 600 }}>{mode === 'dummy' ? 'Test Title' : 'Federal Army'}</Typography>
            <div style={{ margin: '5px 0' }} />
            <Typography style={{ fontWeight: 600 }}>{mode === 'dummy' ? 'Test Description' : 'Special Task Force'}</Typography>
          </div>
          {mode === 'dummy' ? (
            <div style={{ width: 100, height: 50, background: '#1d6ed8', borderRadius: 5 }} />
          ) : (
            <img
              width={80}
              src='https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_Burma_%281948%E2%80%931974%29.svg'
              alt='flag'
              style={{ borderRadius: 5 }}
            />
          )}
        </div>
        <div style={{ width: '85%', margin: '25px 0' }}>
          <Divider />
        </div>
        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
          <Grid item className='stackItemFlex'>
            {selectedItem.photoUrl ? (
              <img
                width={60}
                height={60}
                src={selectedItem.photoUrl}
                alt='flag'
                style={{ borderRadius: '50%' }}
              />
            ) : <div style={{ width: 60, height: 60, borderRadius: '50%', backgroundColor: 'rgba(0, 0, 0, 0.3)' }} />}
            <div style={{ margin: '0 10px' }} />
            <Typography style={{ fontWeight: 600 }}>{selectedItem.name}</Typography>
          </Grid>

          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>ရဲဘော်အမည်:</span>
              <div style={{ margin: '0 6px' }} />
              <span style={{ display: 'table-cell' }}>{selectedItem.codeName}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>မှတ်ပုံတင်အမှတ်:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.nrc?.rawValue}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>အဘအမည်:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.fatherName}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>မွေးသက္ကရာဇ်:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{formatDate(selectedItem.dob)}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>သွေးအုပ်စု:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.bloodType}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>ပညာအရည်အချင်း:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.education}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>အလုပ်အကိုင်:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.occupation}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>လူမျိုး/ဘာသာ:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.ethnicityReligion}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>နေရပ်လိပ်စာ:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.address}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>ဖုန်းနံပါတ်:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.phone}</span>
            </Typography>
          </Grid>
          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText'>
              <span>တဆင့်ဆက်သွယ်ရန်ဖုန်းနံပါတ်:</span>
              <div style={{ margin: '0 6px' }} />
              <span>{selectedItem.secondaryPhone}</span>
            </Typography>
          </Grid>

          <Grid item className='stackItemFlex'>
            <Typography variant='body2' className='cardText' style={{ alignItems: 'center' }}>
              <span>ထိုးမြဲလက်မှတ်:</span>
              <div style={{ margin: '0 6px' }} />
              <img
                width={100}
                src={selectedItem.signatureUrl}
                alt='flag'
                style={{ borderRadius: '50%' }}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
