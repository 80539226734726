import { useRef, useState } from 'react';
// import { useHistory } from 'react-router';
import {
  Container,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PasswordInput from '../../components/common/PasswordInput';
import Notification from '../../components/common/Notification';
import { useAuthContext } from '../../context/AuthProvider';
// import { routes } from '../MainRouter';
import Service from './service';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textfield: {
    width: '100%',
  },
});

export default function LoginPage() {
  const classes = useStyles();

  const { setIsAuthenticated, setMe } = useAuthContext();

  const [error, setError] = useState({
    isError: false,
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const id = useRef('');
  const password = useRef('');

  const login = async () => {
    setLoading(true);

    const idVal = id.current.value;
    const passwordVal = password.current.value;

    try {
      const { profile, creds } = await Service.login(idVal, passwordVal);
      localStorage.setItem('_cred', JSON.stringify(creds));
      setMe(profile);

      setLoading(false);
      setIsAuthenticated(true);
    } catch (err) {
      console.error(err);
      setError({
        isError: true,
        message: err.response ? err.response.data?.message : 'There is an error in connecting to server.',
      });
      setLoading(false);
    }
  };

  return (
    <Container maxWidth='xs' className={classes.root}>
      <Grid
        container
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs={11} sm={8}>
          <TextField inputRef={id} className={classes.textfield} label='ID' size='small' variant='outlined' required />
        </Grid>
        <Grid item xs={11} sm={8}>
          <PasswordInput itemRef={password} className={classes.textfield} label='Password' size='small' variant='outlined' required />
        </Grid>
        <Grid item xs={11} sm={8}>
          <Button variant='contained' color='primary' onClick={login} disabled={loading} disableElevation fullWidth>
            {loading && <CircularProgress size={25} style={{ marginRight: '.5rem' }} />}
            Login
          </Button>
        </Grid>
      </Grid>
      
      <Notification
        severity='error'
        open={error.isError}
        handleClose={() => setError({ ...error, isError: false })}
        message={error.message}
      />
    </Container>
  );
}
