import moment from 'moment';
import {
  Paper,
  Grid,
  Avatar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthContext } from '../../context/AuthProvider';
import { isMobile } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: isMobile ? '100%' : 'fit-content',
    boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.05)',
    padding: theme.spacing(4, 4),
  },
  avatar: {
    fontSize: 28,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  }
}));

export default function ProfileInformation() {
  const classes = useStyles();
  const authContext = useAuthContext();

  return (
    <Paper className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <Avatar className={classes.avatar} alt={authContext.me.adminId}>{authContext.me.adminId[0]}</Avatar>
        </Grid>
        <Grid item>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Typography><b>{authContext.me.adminId}</b></Typography>
            </Grid>
            <Grid item>
              <Typography>Role: {authContext.me.role}</Typography>
            </Grid>
            <Grid item>
              <Typography>Member since {moment(authContext.me.createdAt).format('D MMM YY')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
