import { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { NRC_SR, NRC_TS_CODES, NRC_TYPES } from "../../utils/nrcCodes";

export default function NRCInput({ className, value, onChange, size = 'full' }) {
  const [tsList, setTsList] = useState([]);

  const [sr, setSr] = useState('');
  const [township, setTownship] = useState('');
  const [type, setType] = useState('');
  const [number, setNumber] = useState('');

  useEffect(() => {
    if (value) {
      setSr(value.stateRegion);

      const townships = NRC_TS_CODES[value.stateRegion] || [];
      setTsList(townships);

      setTownship(value.township);
      setType(value.type);
      setNumber(value.number);
    }
  }, [value]);

  useEffect(() => {
    if (!sr) return;

    const townships = NRC_TS_CODES[sr];
    if (townships.indexOf(township) === -1) {
      setTownship('');
    }
    setTsList(townships);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sr]);

  const getData = (sr, ts, tp, num) => ({
    stateRegion: sr,
    township: ts,
    type: tp,
    number: num,
    rawValue: `${sr}/${ts}(${tp})${num}`,
  });

  const handleNRCNumberChange = (e) => {
    const isValid = /^[၀၁၂၃၄၅၆၇၈၉]{0,6}$/gm.test(e.target.value);

    if (isValid) {
      setNumber(e.target.value);
      onChange(getData(sr, township, type, e.target.value));
    }
  };

  return (
    <Grid container spacing={1} style={{ marginTop: 10 }}>
      <Grid item xs={4} sm={size === 'full' ? 4 : 2}>
        <FormControl variant='outlined' size='small' style={{ width: '100%' }}>
          <Select
            value={sr}
            onChange={(e) => {
              setSr(e.target.value);
              onChange(getData(e.target.value, township, type, number));
            }}
          >
            {NRC_SR.map((val) => (
              <MenuItem key={val} value={val}>{val} /</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={size === 'full' ? 4 : 2}>
        <FormControl variant='outlined' size='small' style={{ width: '100%' }}>
          <Select
            value={township}
            onChange={(e) => {
              setTownship(e.target.value);
              onChange(getData(sr, e.target.value, type, number));
            }}
          >
            {tsList.map((val) => (
              <MenuItem key={val} value={val}>{val}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={size === 'full' ? 4 : 2}>
        <FormControl variant='outlined' size='small' style={{ width: '100%' }}>
          <Select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              onChange(getData(sr, township, e.target.value, number));
            }}
          >
            {NRC_TYPES.map((val) => (
              <MenuItem key={val} value={val}>{val}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={size === 'full' ? 12 : 6}>
        <TextField
          size='small'
          variant='outlined'
          required={true}
          style={{ width: '100%' }}
          placeholder='၁၂၃၄၅၆'
          value={number}
          onChange={handleNRCNumberChange}
        />
      </Grid>
    </Grid>
  );
}
